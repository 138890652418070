import { getStorage, ref, uploadBytes } from 'firebase/storage'

export const shortHash = () => Math.random().toString(36).substring(2, 5)
export const getFileType = (filename: string) => filename.split('.')[filename.split('.').length - 1]
export const getFileName = (filename: string) => filename.split('.')[0]

export async function uploadFile(file: File, resource = true) {
  const storage = getStorage()
  const bucket = resource ? 'resources' : 'files'
  const filename = `${getFileName(file.name)}_${shortHash()}.${getFileType(file.name)}`
  const fileRef = ref(storage, `${bucket}/${filename}`)
  await uploadBytes(fileRef, file)
  return filename
}
