import { get, getDatabase, ref, set } from 'firebase/database'
import { defineStore } from 'pinia'
import { onMounted, ref as vueRef } from 'vue'

import { DATABASE_URL_ASSOCIATE } from '../../firebase'

export const useAssociateContactStore = defineStore('associate-contact', () => {
  const html = vueRef('')
  const populated = vueRef(false)

  onMounted(async () => {
    if (populated.value) return
    await populate()
    populated.value = true
  })

  async function populate() {
    const db = getDatabase(undefined, DATABASE_URL_ASSOCIATE)
    const dbRef = ref(db, 'contact')
    const snapshot = await get(dbRef)

    if (!snapshot.exists()) return

    html.value = snapshot.val() as string
  }

  async function update(value: string) {
    const db = getDatabase(undefined, DATABASE_URL_ASSOCIATE)
    const dbRef = ref(db, 'contact')
    await set(dbRef, value)
    html.value = value
  }

  return { html, update, populate }
})
