<template>
  <div class="w-full h-full p-6 flex flex-col items-center">
    <RichTextEditor
      v-model="value"
      fullHeight
      class="border-2 rounded focus:outline-none border-gray-300 focus:border-blue-600 w-full bg-white max-w-screen-lg"
    />
    <div class="flex w-full mt-4 space-x-4 justify-end max-w-screen-lg">
      <VariantButton variant="blue" @click="save">Lagre</VariantButton>
      <VariantButton variant="gray" @click="reset" :disabled="disabled">Tilbakestill</VariantButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import RichTextEditor from '../../components/RichTextEditor.vue'
import VariantButton from '../../components/VariantButton.vue'
import { useToaster } from '../../hooks/useToaster'
import { useAssociateContactStore } from '../../stores/associate/contact'

const value = ref('')
const toaster = useToaster()
const associateContactStore = useAssociateContactStore()

const contactInfo = computed(() => associateContactStore.html)
const disabled = computed(() => value.value === contactInfo.value)

const save = async () => {
  try {
    associateContactStore.update(value.value)
    toaster.success('Suksess!', 'Oppdaterte kontaktinfo.')
  } catch (e) {
    console.error(e)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

const reset = () => {
  value.value = contactInfo.value
  toaster.info('Tilbakestill', 'Kontaktinfo tilbakestilt.')
}

watch(contactInfo, () => {
  if (contactInfo.value) {
    value.value = contactInfo.value
  }
})

watch(
  () => contactInfo.value,
  (newVal) => {
    value.value = newVal
  }
)
</script>

<style lang="scss"></style>
