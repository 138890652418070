import { computed } from 'vue'

import { useToastStore } from '../stores/extra/toasts'
import { ToastType } from '../types'

const randomString = () => Math.random().toString(36).substring(2, 8)

export function useToaster() {
  const toastStore = useToastStore()

  const toasts = computed(() => toastStore.toasts)

  function toast(type: ToastType, header: string, text: string) {
    const id = randomString()
    const toast = {
      id,
      type,
      header,
      text,
    }
    toastStore.toasts.push(toast)
    return id
  }

  function closeToast(id: string) {
    toastStore.remove(id)
  }

  function success(header: string, text: string) {
    return toast('success', header, text)
  }
  function error(header: string, text: string) {
    return toast('error', header, text)
  }
  function info(header: string, text: string) {
    return toast('info', header, text)
  }

  return {
    toasts,
    success,
    error,
    info,
    closeToast,
  }
}
