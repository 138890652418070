<template>
  <div class="flex flex-col items-center px-2 py-4 sm:px-8 sm:py-12 md:px-12 md:py-16">
    <Table :headers="headers" :data="tickets" @row-click="openRow" />
    <div class="max-w-screen-lg w-full flex justify-end mt-6">
      <VariantButton variant="blue" @click="createNew">Legg til ny</VariantButton>
    </div>
    <Modal v-if="selectedRow" @close="selectedRow = null" :showClose="true" :backgroundClose="true">
      <div class="flex flex-col space-y-2 w-full">
        <h1 class="text-2xl font-semibold">
          {{ selectedRow && selectedRow.id ? 'Rediger om-artikkel' : 'Ny om-artikkel' }}
        </h1>
        <TextField v-model="selectedRow.title" placeholder="Tittel" />
        <div class="flex space-x-2">
          <TextField v-model="selectedRow.link" placeholder="Billett-link" class="w-2/3" />
          <TextField v-model="selectedRow.price" placeholder="Pris" class="w-1/3" />
        </div>
        <RichTextEditor
          class="border-2 rounded focus:outline-none border-gray-300 focus:border-blue-600 w-full bg-white overflow-auto"
          v-model="selectedRow.perks"
        />
        <div class="flex space-x-2 justify-end">
          <VariantButton variant="blue" @click="save">Lagre</VariantButton>
          <VariantButton v-if="deletable" variant="red" @click="deleteTicket">Slett</VariantButton>
          <VariantButton variant="gray" @click="selectedRow = null">Avbryt</VariantButton>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'

import Modal from '../components/Modal.vue'
import RichTextEditor from '../components/RichTextEditor.vue'
import Table from '../components/Table.vue'
import TextField from '../components/TextField.vue'
import VariantButton from '../components/VariantButton.vue'
import { useToaster } from '../hooks/useToaster'
import { useTicketStore } from '../stores/main/tickets'
import { deepcopy } from '../stores/utils'
import { Header, Ticket } from '../types'

const selectedRow = ref<Ticket | null>(null)
const headers: Header<Ticket>[] = [
  {
    name: 'Tittel',
    key: 'title',
  },
  {
    name: 'Billett-lenke',
    key: 'link',
  },
  {
    name: 'Pris',
    key: 'price',
  },
]

const toaster = useToaster()
const ticketStore = useTicketStore()

const tickets = computed(() => ticketStore.list.toSorted((a, b) => (a.title > b.title ? 1 : -1)))
const deletable = computed(() => !!selectedRow.value?.id)

async function deleteTicket() {
  if (!selectedRow.value) return
  try {
    await ticketStore.remove(selectedRow.value)
    selectedRow.value = null
    toaster.success('Suksess!', 'Slettet billett.')
  } catch (error) {
    console.error(error)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

function openRow(rowIndex: number) {
  selectedRow.value = deepcopy(tickets.value[rowIndex])
}

function createNew() {
  selectedRow.value = {
    id: '',
    title: '',
    link: '',
    perks: '',
    price: '',
  }
}

async function save() {
  if (!selectedRow.value) return
  if (selectedRow.value.id) {
    try {
      await ticketStore.update(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'Oppdaterte billett.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  } else {
    try {
      await ticketStore.add(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'La til ny billett.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  }
}
</script>

<style lang="scss"></style>
