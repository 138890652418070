<template>
  <div class="flex flex-col items-center px-2 py-4 sm:px-8 sm:py-12 md:px-12 md:py-16">
    <Table :headers="headers" :data="programPosts" @row-click="openRow" />
    <div class="max-w-screen-lg w-full flex justify-end mt-6">
      <VariantButton variant="blue" @click="createNew">Legg til ny</VariantButton>
    </div>
    <Modal v-if="selectedRow" @close="selectedRow = null" :showClose="true" :backgroundClose="true">
      <div class="flex flex-col space-y-2 w-full">
        <h1 class="text-2xl font-semibold">
          {{ selectedRow && selectedRow.id ? 'Rediger programpost' : 'Ny programpost' }}
        </h1>
        <div class="flex space-x-2">
          <TextField v-model="selectedRow.title" placeholder="Tittel" />
          <DateField v-model="selectedRow.timestamp" placeholder="Tidspunkt" />
        </div>
        <div class="flex space-x-2">
          <TextField v-model="selectedRow.image" placeholder="BildeURL" />
          <TextField v-model="selectedRow.location" placeholder="Lokasjon" />
          <SelectField v-model="selectedRow.type" placeholder="Type" :options="types" />
        </div>
        <RichTextEditor class="border-2 rounded focus:outline-none w-full" v-model="selectedRow.description" />
        <div class="flex justify-between">
          <Toggle v-model="selectedRow.ulPluss" label="UL-pluss-hendelse" class="flex-grow-0" />
          <div class="flex space-x-2">
            <VariantButton variant="blue" @click="save">Lagre</VariantButton>
            <VariantButton v-if="deletable" variant="red" @click="deleteArticle">Slett</VariantButton>
            <VariantButton variant="gray" @click="selectedRow = null">Avbryt</VariantButton>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import DateField from '../components/DateField.vue'
import Modal from '../components/Modal.vue'
import RichTextEditor from '../components/RichTextEditor.vue'
import SelectField from '../components/SelectField.vue'
import Table from '../components/Table.vue'
import TextField from '../components/TextField.vue'
import Toggle from '../components/Toggle.vue'
import VariantButton from '../components/VariantButton.vue'
import { useToaster } from '../hooks/useToaster'
import { useEventStore } from '../stores/main/events'
import { deepcopy } from '../stores/utils'
import { Event, Header } from '../types'

const selectedRow = ref<Event | null>(null)
const headers: Header<Event>[] = [
  {
    name: 'UL+',
    key: 'ulPluss',
    boolean: true,
  },
  {
    name: 'Tittel',
    key: 'title',
  },
  {
    name: 'Beskrivelse',
    key: 'description',
  },
  {
    name: 'Type',
    key: 'type',
  },
  {
    name: 'Sted',
    key: 'location',
  },
  {
    name: 'Tidspunkt',
    key: 'timestamp',
    date: true,
  },
]
const types = ['Møte', 'Seminar', 'Aktivitet', 'Underholdning', 'Annet']

const toaster = useToaster()
const eventStore = useEventStore()

const programPosts = computed(() => eventStore.list.toSorted((a, b) => (a.timestamp > b.timestamp ? 1 : -1)))
const deletable = computed(() => !!selectedRow.value?.id)

function deleteArticle() {
  if (!selectedRow.value) return
  try {
    eventStore.remove(selectedRow.value)
    selectedRow.value = null
    toaster.success('Suksess!', 'Slettet event.')
  } catch (error) {
    console.error(error)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

function openRow(rowIndex: number) {
  selectedRow.value = deepcopy(programPosts.value[rowIndex])
}

function createNew() {
  selectedRow.value = {
    id: '',
    title: '',
    image: '',
    description: '',
    location: '',
    preacher: '',
    timestamp: '',
    type: 'Møte',
    ulPluss: false,
  }
}

async function save() {
  if (!selectedRow.value) return
  if (selectedRow.value?.id) {
    try {
      await eventStore.update(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'Oppdaterte event.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  } else {
    try {
      await eventStore.add(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'La til nytt event.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  }
}
</script>

<style lang="scss"></style>
