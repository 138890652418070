import { acceptHMRUpdate, defineStore } from 'pinia'

import { DATABASE_URL_ASSOCIATE } from '../../firebase'
import { useReactiveList } from '../../hooks/useReactiveList'
import { Resource } from '../../types'
import { uploadFile } from '../utils/fileupload'

// Duplikat av stores/main/resources, denne brukes for bilder
export const useAssociateResourceStore = defineStore('associate-resources', () => {
  const {
    add: addToDb,
    list,
    populate,
    remove,
    update,
  } = useReactiveList<Resource>('resources', DATABASE_URL_ASSOCIATE)

  async function add(file: File) {
    const filename = await uploadFile(file, true)
    await addToDb({
      filename,
      title: file.name,
      subtitle: '',
    })
  }

  return { add, list, populate, remove, update }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAssociateResourceStore, import.meta.hot))
}
