import { acceptHMRUpdate, defineStore } from 'pinia'
import { reactive } from 'vue'

import { Toast } from '../../types'

export const useToastStore = defineStore('toasts', () => {
  const toasts = reactive([]) as Toast[]

  function remove(id: string) {
    const index = toasts.findIndex((toast) => toast.id === id)
    if (index === -1) throw new Error("Can't remove toast not in list.")
    toasts.splice(index, 1)
  }

  return {
    toasts,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useToastStore, import.meta.hot))
}
