<template>
  <div class="p-2 sm:p-12 w-full">
    <div class="m-auto max-w-screen-lg flex flex-col space-y-4">
      <h1 class="text-gray-800 text-3xl text-left w-full">Nytt push-varsel</h1>
      <TextField v-model.trim="title" placeholder="Tittel" />
      <TextArea v-model.trim="body" placeholder="Brødtekst" />
      <div class="w-full flex justify-end">
        <VariantButton variant="blue" @click="push">Send varsel</VariantButton>
      </div>

      <h1 class="text-gray-800 text-3xl text-left w-full">Tidligere varsler</h1>
      <Table :headers="headers" :data="notifications" noLinks />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import Table from '../components/Table.vue'
import TextArea from '../components/TextArea.vue'
import TextField from '../components/TextField.vue'
import VariantButton from '../components/VariantButton.vue'
import { useNotificationStore } from '../stores/extra/notifications'
import { Header, Notification } from '../types'

const notificationStore = useNotificationStore()

const title = ref('')
const body = ref('')

const headers: Header<Notification>[] = [
  {
    name: 'Tittel',
    key: 'title',
  },
  {
    name: 'Brødtekst',
    key: 'body',
  },
]

const notifications = computed(() => notificationStore.list.toReversed())

function push() {
  notificationStore.add({ title: title.value, body: body.value })
  title.value = ''
  body.value = ''
}
</script>

<style lang="scss"></style>
