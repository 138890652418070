<template>
  <div class="flex flex-col items-center px-2 py-4 sm:px-8 sm:py-12 md:px-12 md:py-16">
    <Table :headers="headers" :data="articles" @row-click="openRow" />
    <div class="max-w-screen-lg w-full flex justify-end mt-6">
      <VariantButton variant="blue" @click="createNew">Legg til ny</VariantButton>
    </div>
    <Modal v-if="selectedRow" @close="selectedRow = null" :showClose="true" :backgroundClose="true">
      <div class="flex flex-col space-y-2 w-full">
        <h1 class="text-2xl font-semibold">
          {{ selectedRow && selectedRow.id ? 'Rediger info-artikkel' : 'Ny info-artikkel' }}
        </h1>
        <TextField v-model="selectedRow.title" placeholder="Tittel" />
        <div class="flex space-x-2">
          <IconField
            v-model="selectedRow.icon"
            placeholder="Phosphor icons ikon-navn (f.eks. address-book)"
            class="w-2/3"
          />
          <NumberField v-model="selectedRow.priority" placeholder="Prioritet" class="w-1/3" />
        </div>
        <RichTextEditor
          class="border-2 rounded focus:outline-none border-gray-300 focus:border-blue-600 w-full bg-white overflow-auto"
          v-model="selectedRow.text"
        />
        <div class="flex space-x-2 justify-end">
          <VariantButton variant="blue" @click="save">Lagre</VariantButton>
          <VariantButton v-if="deletable" variant="red" @click="deleteArticle">Slett</VariantButton>
          <VariantButton variant="gray" @click="selectedRow = null">Avbryt</VariantButton>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'

import IconField from '../../components/IconField.vue'
import Modal from '../../components/Modal.vue'
import NumberField from '../../components/NumberField.vue'
import RichTextEditor from '../../components/RichTextEditor.vue'
import Table from '../../components/Table.vue'
import TextField from '../../components/TextField.vue'
import VariantButton from '../../components/VariantButton.vue'
import { useToaster } from '../../hooks/useToaster'
import { useAssociateInformationStore } from '../../stores/associate/information'
import { deepcopy } from '../../stores/utils'
import { Header, InformationArticle } from '../../types'

const toaster = useToaster()
const associateInformationStore = useAssociateInformationStore()

const selectedRow = ref<InformationArticle | null>(null)
const headers: Header<InformationArticle>[] = [
  {
    name: 'Tittel',
    key: 'title',
  },
  {
    name: 'Beskrivelse',
    key: 'text',
  },
  {
    name: 'Prioritet',
    key: 'priority',
  },
  {
    name: 'Ikon',
    key: 'icon',
  },
]

const articles = computed(() => associateInformationStore.list.toSorted((a, b) => b.priority - a.priority))
const deletable = computed(() => !!selectedRow.value?.id)

const deleteArticle = async () => {
  if (!selectedRow.value) return
  try {
    await associateInformationStore.remove(selectedRow.value)
    selectedRow.value = null
    toaster.success('Suksess!', 'Slettet om-artikkel.')
  } catch (error) {
    console.error(error)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

const openRow = (rowIndex: number) => {
  selectedRow.value = deepcopy(articles.value[rowIndex])
}

const createNew = () => {
  selectedRow.value = {
    id: '',
    title: '',
    icon: '',
    priority: 0,
    text: '',
  }
}

const save = async () => {
  if (!selectedRow.value) return
  if (selectedRow.value.id) {
    try {
      await associateInformationStore.update(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'Oppdaterte info-artikkel.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  } else {
    try {
      await associateInformationStore.add(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'La til ny info-artikkel.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  }
}
</script>

<style lang="scss"></style>
