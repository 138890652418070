<template>
  <div class="w-full h-full flex flex-col items-center space-y-2 px-2 py-4 sm:px-8 sm:py-12 md:px-12 md:py-16">
    <div class="flex flex-row space-x-4 w-full max-w-screen-lg">
      <TextField v-model="idValue" placeholder="Stream-link" class="flex-grow w-full" />
      <VariantButton variant="blue" @click="saveId">Lagre</VariantButton>
      <VariantButton variant="gray" @click="resetId" :disabled="disabledResetId">Tilbakestill</VariantButton>
    </div>
    <p class="text-sm text-gray-500 w-full max-w-screen-lg text-left leading-loose">
      Dersom lenken er <Mono>https://www.youtube.com/watch?v=dKqfoaRmWYk&ref=google.com</Mono>, så skal du kun putte inn
      "v="-verdien, altså <Mono>dKqfoaRmWYk</Mono>. Dersom linken er
      <Mono>https://www.youtube.com/live/xVIAOgY19DM</Mono>, bruk <Mono>xVIAOgY19DM</Mono>.
    </p>

    <p class="pt-12 text-sm text-gray-400 italic w-full max-w-screen-lg text-left">Neste stream</p>
    <div class="flex flex-row space-x-4 w-full max-w-screen-lg">
      <DateField v-model="nextStreamValue" class="flex-grow-0 w-full max-w-screen-lg" />
      <VariantButton variant="blue" @click="saveDate">Lagre</VariantButton>
      <VariantButton variant="gray" @click="resetDate" :disabled="disabledResetDate">Tilbakestill</VariantButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import DateField from '../components/DateField.vue'
import Mono from '../components/Mono.vue'
import TextField from '../components/TextField.vue'
import VariantButton from '../components/VariantButton.vue'
import { useToaster } from '../hooks/useToaster'
import { useStreamStore } from '../stores/main/stream'

const idValue = ref('')
const nextStreamValue = ref('')

const toaster = useToaster()
const streamStore = useStreamStore()

const streamId = computed(() => streamStore.streamId)
const disabledResetId = computed(() => idValue.value === streamId.value)

const nextStream = computed(() => streamStore.nextStream)
const disabledResetDate = computed(() => nextStreamValue.value === nextStream.value)

async function saveId() {
  try {
    streamStore.updateId(idValue.value)
    toaster.success('Suksess!', 'Oppdaterte stream-link.')
  } catch (e) {
    console.error(e)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

function resetId() {
  idValue.value = streamId.value
  toaster.info('Tilbakestill', 'Stream-link tilbakestilt.')
}

async function saveDate() {
  try {
    streamStore.updateNextStream(nextStreamValue.value)
    toaster.success('Suksess!', 'Oppdaterte dato for neste stream.')
  } catch (e) {
    console.error(e)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

function resetDate() {
  nextStreamValue.value = nextStream.value
  toaster.info('Tilbakestill', 'Dato for neste stream tilbakestilt.')
}

watch(streamId, (newVal) => {
  if (newVal) {
    idValue.value = newVal
  }
})

watch(nextStream, (newVal) => {
  if (newVal) {
    nextStreamValue.value = newVal
  }
})
</script>

<style lang="scss"></style>
