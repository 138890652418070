<template>
  <div id="app" class="flex flex-col w-full overflow-x-hidden">
    <Header v-if="loggedIn" />
    <div class="flex w-full overflow-x-hidden">
      <Navigation v-if="loggedIn" />
      <router-view
        :class="[
          'flex-grow bg-gray-100 ul-scroll',
          loggedIn ? 'flex-grow h-view overflow-auto' : 'flex-grow h-screen overflow-hidden',
        ]"
      />
    </div>
    <Toaster />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import Header from './components/Header.vue'
import Navigation from './components/Navigation.vue'
import Toaster from './components/Toaster.vue'
import { useRootStore } from './stores/root'

const rootStore = useRootStore()
onMounted(async () => await rootStore.populate())

const route = useRoute()
const loggedIn = computed(() => route.name !== 'login')
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

.ul-scroll {
  &::-webkit-scrollbar {
    @apply w-2;
    @apply bg-gray-200;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-400;
  }

  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500;
  }

  &::-webkit-scrollbar-thumb:active {
    @apply bg-gray-600;
  }
}
</style>
