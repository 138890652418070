<template>
  <div class="w-96 h-16 z-50 bg-gray-100 rounded-xl flex flex-row shadow overflow-hidden">
    <div class="w-1/5 flex justify-center items-center" :class="[color]">
      <svg v-if="type === 'success'" class="text-white" style="width: 24px; height: 24px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
        />
      </svg>
      <svg v-if="type === 'error'" class="text-white" style="width: 24px; height: 24px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
        />
      </svg>
      <svg v-if="type === 'info'" class="text-white" style="width: 24px; height: 24px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
        />
      </svg>
    </div>
    <div class="w-3/5 flex flex-col justify-center whitespace-no-wrap">
      <h4 class="px-6 text-gray-800 font-semibold">{{ header }}</h4>
      <p class="px-6 text-gray-800">{{ text }}</p>
    </div>
    <button
      class="w-1/5 flex flex-col justify-center items-center focus:outline-none hover:bg-gray-200 text-gray-800"
      @click="closeToast"
    >
      Lukk
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'

const emit = defineEmits<{
  close: []
}>()

type Props = {
  type?: 'success' | 'error' | 'info'
  header?: string
  text?: string
}
const props = withDefaults(defineProps<Props>(), {
  type: 'info',
  header: '',
  text: '',
})

const color = computed(() => {
  if (props.type === 'success') return 'bg-green-400'
  if (props.type === 'error') return 'bg-red-400'
  return 'bg-blue-400'
})

function closeToast() {
  emit('close')
}

onMounted(() => {
  setTimeout(closeToast, 5000)
})
</script>

<style lang="scss"></style>
