<template>
  <div
    v-if="show"
    @mousedown.self="close(true)"
    class="fixed inset-0 w-full h-screen flex items-center justify-center bg-black bg-opacity-25 z-1"
  >
    <div
      class="relative max-h-screen w-full mx-4 md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg bg-white shadow-lg rounded-lg p-8 flex"
    >
      <button
        v-if="showClose"
        @click="close(false)"
        class="absolute top-0 right-0 my-2 mx-2 px-2 text-gray-500 text-xl"
      >
        &times;
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  close: []
}>()

type Props = {
  show?: boolean
  showClose?: boolean
  backgroundClose?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  show: true,
  showClose: false,
  backgroundClose: false,
})

function close(backgroundClick: boolean) {
  if (!backgroundClick || (backgroundClick && props.backgroundClose)) {
    emit('close')
  }
}
</script>

<style lang="scss"></style>
