<template>
  <div class="flex flex-col items-center px-2 py-4 sm:px-8 sm:py-12 md:px-12 md:py-16">
    <Table :headers="headers" :data="sponsors" @row-click="openRow" />
    <div class="max-w-screen-lg w-full flex justify-end mt-6">
      <VariantButton variant="blue" @click="createNew">Legg til ny</VariantButton>
    </div>
    <Modal v-if="selectedRow" @close="selectedRow = null" :showClose="true" :backgroundClose="true">
      <div class="flex flex-col space-y-2 w-full">
        <h1 class="text-2xl font-semibold">
          {{ selectedRow && selectedRow.id ? 'Rediger sponsor' : 'Ny sponsor' }}
        </h1>
        <TextField v-model="selectedRow.name" placeholder="Navn" />
        <TextField v-model="selectedRow.image" placeholder="BildeURL" />
        <TextField v-model="selectedRow.link" placeholder="Link" />
        <div class="flex space-x-2 justify-end">
          <VariantButton variant="blue" @click="save">Lagre</VariantButton>
          <VariantButton v-if="deletable" variant="red" @click="deleteSelectedSponsor">Slett</VariantButton>
          <VariantButton variant="gray" @click="selectedRow = null">Avbryt</VariantButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import Modal from '../components/Modal.vue'
import Table from '../components/Table.vue'
import TextField from '../components/TextField.vue'
import VariantButton from '../components/VariantButton.vue'
import { useToaster } from '../hooks/useToaster'
import { useSponsorStore } from '../stores/main/sponsors'
import { deepcopy } from '../stores/utils'
import { Header, Sponsor } from '../types'

const selectedRow = ref<Sponsor | null>(null)
const headers: Header<Sponsor>[] = [
  {
    name: 'Navn',
    key: 'name',
  },
  {
    name: 'Bilde',
    key: 'image',
  },
  {
    name: 'Link',
    key: 'link',
  },
]

const toaster = useToaster()
const sponsorStore = useSponsorStore()

const sponsors = sponsorStore.list
const deletable = computed(() => !!selectedRow.value?.id)

async function deleteSelectedSponsor() {
  if (!selectedRow.value) return
  try {
    await sponsorStore.remove(selectedRow.value)
    selectedRow.value = null
    toaster.success('Suksess!', 'Slettet sponsor.')
  } catch (error) {
    console.error(error)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

function openRow(rowIndex: number) {
  selectedRow.value = deepcopy(sponsors[rowIndex])
}

function createNew() {
  selectedRow.value = {
    id: '',
    name: '',
    image: '',
    link: '',
  }
}

async function save() {
  if (!selectedRow.value) return
  if (selectedRow.value.id) {
    try {
      await sponsorStore.update(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'Oppdaterte sponsor.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  } else {
    try {
      await sponsorStore.add(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'La til ny sponsor.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  }
}
</script>

<style lang="scss"></style>
