<template>
  <div class="flex flex-col items-center px-2 py-4 sm:px-8 sm:py-12 md:px-12 md:py-16">
    <Table :headers="headers" :data="files" @row-click="openRow" />
    <div class="max-w-screen-lg w-full flex justify-end mt-6">
      <input type="file" id="file-input" class="hidden" @input="handleUpload" />
      <VariantButton variant="blue" @click="uploadFile">Last opp ny fil</VariantButton>
    </div>
    <Modal v-if="selectedRow" @close="selectedRow = null" :showClose="true" :backgroundClose="true">
      <div class="flex flex-col space-y-2 w-full">
        <h1 class="text-2xl font-semibold">
          {{ selectedRow && selectedRow.id ? 'Rediger ressurs' : 'Ny ressurs' }}
        </h1>
        <TextField v-model="selectedRow.title" placeholder="Hovedtittel (f.eks. sang-tittel)" />
        <TextField v-model="selectedRow.subtitle" placeholder="Undertittel (f.eks. toneart eller type dokument)" />
        <div class="h-96" v-if="isPdf">
          <iframe :src="fileUrl" frameborder="0" class="w-full h-full bg-gray-800"></iframe>
        </div>
        <div class="h-96 flex justify-center items-center" v-else-if="isImage">
          <img :src="fileUrl" class="max-w-full max-h-full" />
        </div>
        <div v-else class="text-center w-full p-4 text-lg">
          <p>Filen kan ikke forhåndsvises.</p>
          <p>
            <a :href="fileUrl" target="_blank" class="underline text-blue-600">Klikk her</a>
            for å laste den ned istedet.
          </p>
        </div>
        <div class="flex space-x-2 justify-end">
          <VariantButton variant="blue" @click="save">Lagre</VariantButton>
          <VariantButton v-if="deletable" variant="red" @click="deleteFile">Slett</VariantButton>
          <VariantButton variant="gray" @click="selectedRow = null">Avbryt</VariantButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import Modal from '../components/Modal.vue'
import Table from '../components/Table.vue'
import TextField from '../components/TextField.vue'
import VariantButton from '../components/VariantButton.vue'
import { useToaster } from '../hooks/useToaster'
import { useResourceStore } from '../stores/main/resources'
import { deepcopy } from '../stores/utils'
import { Header, Resource } from '../types'

const selectedRow = ref<Resource | null>(null)
const headers: Header<Resource>[] = [
  {
    name: 'Hovedtittel',
    key: 'title',
  },
  {
    name: 'Undertittel',
    key: 'subtitle',
  },
  {
    name: 'Filnavn (skjult)',
    key: 'filename',
  },
]

const toaster = useToaster()
const resourceStore = useResourceStore()

const files = computed(
  () =>
    resourceStore.list
      .toSorted((a, b) => (a.subtitle > b.subtitle ? 1 : -1)) // sorter sekundært på undertittel
      .toSorted((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0)) //... og primært på tittel, men ivareta interne sorteringer dersom lik tittel
      .toSorted((a, b) => (b.title === 'Plakater' ? 1 : a.title == 'Plakater' ? -1 : 0)) //... og prioriter plakater først!
)
const fileUrl = computed(() =>
  selectedRow.value
    ? `https://firebasestorage.googleapis.com/v0/b/ulno-1557913587891.appspot.com/o/resources%2F${selectedRow.value.filename}?alt=media`
    : ''
)
const fileType = computed(() =>
  selectedRow.value ? selectedRow.value.filename.split('.')[selectedRow.value.filename.split('.').length - 1] : null
)

const isPdf = computed(() => fileType.value === 'pdf')
const isImage = computed(() => ['jpg', 'jpeg', 'png', 'gif', 'webm'].includes(fileType.value ?? ''))
const deletable = computed(() => !!selectedRow.value?.id)

function openRow(rowIndex: number) {
  selectedRow.value = deepcopy(files.value[rowIndex])
}

function uploadFile() {
  const fileInput = document.querySelector<HTMLInputElement>('input#file-input')
  fileInput?.click()
}

async function handleUpload() {
  const fileInput = document.querySelector<HTMLInputElement>('input#file-input')
  if (!fileInput?.files?.length) {
    console.error("Either didn't find the fileInput, or no files were selected.")
    return toaster.error('Oi oi oi!', 'Noe gikk galt, gitt.')
  }
  toaster.info('Et øyeblikk...', 'Laster opp fil.')
  try {
    await resourceStore.add(fileInput.files[0])
    selectedRow.value = null
    fileInput.value = ''
    toaster.success('Suksess!', 'La til ny ressurs.')
  } catch (error) {
    console.error(error)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

async function save() {
  if (!selectedRow.value) return
  if (selectedRow.value.id) {
    try {
      await resourceStore.update(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'Oppdaterte ressurs.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  }
}

async function deleteFile() {
  if (!selectedRow.value) return
  try {
    await resourceStore.remove(selectedRow.value)
    selectedRow.value = null
    toaster.success('Suksess!', 'Slettet ressurs.')
  } catch (error) {
    console.error(error)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}
</script>

<style lang="scss"></style>
