import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { createPinia } from 'pinia'
import { createApp, App as VueApp } from 'vue'

import App from './App.vue'
import { firebaseConfig } from './firebase'
import router from './router'

const firebaseApp = initializeApp(firebaseConfig)

const pinia = createPinia()

let app: VueApp<Element>
const auth = getAuth(firebaseApp)
onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App)

    app.use(router)
    app.use(pinia)

    app.mount('#app')
  }
})
