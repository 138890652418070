<template>
  <div class="flex flex-col items-center px-2 py-4 sm:px-8 sm:py-12 md:px-12 md:py-16">
    <Table :headers="headers" :data="files" @row-click="openRow" />
    <div class="max-w-screen-lg w-full flex justify-end">
      <input type="file" id="file-input" class="hidden" @input="beginUpload" />
      <button
        class="border-2 rounded focus:outline-none self-end border-gray-300 hover:border-blue-600 focus:border-blue-600 active:border-blue-800 bg-gray-200 hover:bg-blue-200 focus:bg-blue-200 active:bg-blue-300 text-gray-800 hover:text-blue-900 focus:text-blue-900 py-4 px-6 my-6 font-semibold"
        @click="uploadFile"
      >
        Last opp ny fil
      </button>
    </div>
    <Modal v-if="selectedRow" @close="selectedRow = null" :showClose="true" :backgroundClose="true">
      <div class="flex flex-col space-y-2 w-full">
        <h1 class="text-2xl font-semibold">
          {{ selectedRow && selectedRow.id ? 'Rediger bilde' : 'Nytt bilde' }}
        </h1>
        <TextField v-model="selectedRow.title" placeholder="Tittel" />
        <TextField
          v-model="fileUrl"
          placeholder="BildeURL"
          readonly
          @click="copyFileUrl"
          title="Klikk for å kopiere"
          class="cursor-pointer"
        />
        <div class="h-96 flex justify-center items-center" v-if="previewFile">
          <img :src="fileUrl" frameborder="0" class="max-w-full max-h-full" />
        </div>
        <div v-else class="text-center w-full p-4 text-lg">
          <p>Filen kunne ikke forhåndsvises her.</p>
          <p>
            <a :href="fileUrl" target="_blank" class="underline text-blue-600">Klikk her</a>
            for å laste den ned istedet.
          </p>
        </div>
        <div class="flex space-x-2 justify-end">
          <VariantButton variant="blue" @click="save">Lagre</VariantButton>
          <VariantButton v-if="deletable" variant="red" @click="deleteFile">Slett</VariantButton>
          <VariantButton variant="gray" @click="selectedRow = null">Avbryt</VariantButton>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import Modal from '../components/Modal.vue'
import Table from '../components/Table.vue'
import TextField from '../components/TextField.vue'
import VariantButton from '../components/VariantButton.vue'
import { useToaster } from '../hooks/useToaster'
import { useFileStore } from '../stores/extra/files'
import { deepcopy } from '../stores/utils'
import { Header, type ResourceFile } from '../types'

const selectedRow = ref<ResourceFile | null>(null)
const headers: Header<ResourceFile>[] = [
  {
    name: 'Tittel',
    key: 'title',
  },
  {
    name: 'Filnavn (skjult)',
    key: 'filename',
  },
]
const fileForUpload = ref<File | null>(null)

const toaster = useToaster()
const fileStore = useFileStore()
const files = computed(() =>
  fileStore.list.toSorted((a, b) => (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ? 1 : -1))
)

const fileUrl = computed(() =>
  selectedRow.value
    ? `https://firebasestorage.googleapis.com/v0/b/ulno-1557913587891.appspot.com/o/files%2F${selectedRow.value.filename}?alt=media`
    : ''
)
const fileType = computed(() =>
  selectedRow.value ? selectedRow.value.filename.split('.')[selectedRow.value.filename.split('.').length - 1] : null
)
const previewFile = computed(() => ['jpg', 'jpeg', 'png', 'gif', 'webm'].includes(fileType.value ?? ''))
const deletable = computed(() => !!selectedRow.value?.id)

function openRow(rowIndex: number) {
  selectedRow.value = deepcopy(files.value[rowIndex])
}

function uploadFile() {
  const fileInput = document.querySelector<HTMLInputElement>('input#file-input')
  fileInput?.click()
}

function copyFileUrl() {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(fileUrl.value)
    toaster.success('Kopierte url', 'Lenken ble kopiert til utklippstavlen')
  }
}

async function beginUpload() {
  const fileInput = document.querySelector<HTMLInputElement>('input#file-input')
  if (!fileInput?.files) return
  const file = fileInput.files[0]
  const fileType = file.name.split('.')[file.name.split('.').length - 1]
  if (!['jpg', 'jpeg', 'png', 'gif', 'webm'].includes(fileType)) {
    toaster.error('Feil filtype, altså!', 'Kun bilder her!')
    fileInput.value = ''
    return
  }
  toaster.info('Et øyeblikk...', 'Laster opp fil.')
  scaleImage(file)
  fileInput.value = ''
}

async function completeUpload(file: File) {
  try {
    await fileStore.add(file)
    toaster.success('Suksess!', 'La til nytt bilde.')
  } catch (error) {
    console.error(error)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
  selectedRow.value = null
  fileForUpload.value = null
}

async function save() {
  if (!selectedRow.value) return
  if (selectedRow.value.id) {
    try {
      await fileStore.update(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'Oppdaterte bildet.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  }
}

async function deleteFile() {
  if (!selectedRow.value) return
  try {
    await fileStore.remove(selectedRow.value)
    selectedRow.value = null
    toaster.success('Suksess!', 'Slettet bildet.')
  } catch (error) {
    console.error(error)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

async function scaleImage(file: File) {
  const fileReader = new FileReader()
  fileReader.onload = (e: ProgressEvent<FileReader>) => {
    const img = new Image()
    img.onload = async () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) throw new Error('Could not get canvas 2d context')
      const MAX_SIZE = 2000
      let width = img.width
      let height = img.height
      if (width >= height && width > MAX_SIZE) {
        // if the image is wider than it is tall, and it's too wide...
        height *= MAX_SIZE / width
        width = MAX_SIZE
      } else if (height > width && height > MAX_SIZE) {
        // if the image is taller than it is wide, and it's too tall...
        width *= MAX_SIZE / height
        height = MAX_SIZE
      } else {
        // otherwise we don't really need scaling, do we? Use the original file.
        fileForUpload.value = file
        return
      }
      toaster.info('Skalerer ned bildet...', '...for å spare båndbredde!')
      canvas.width = width
      canvas.height = height
      ctx.drawImage(img, 0, 0, width, height)
      // This is the result of 3 hours of debugging, and I feel a mix of
      // pride and shame for finishing it, and it taking so long.
      const dataUrl = canvas.toDataURL(file.type)
      const simulatedFetchResponse = await fetch(dataUrl)
      const arrayBuffer = await simulatedFetchResponse.arrayBuffer()
      const parsedFile = new File([arrayBuffer], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      })
      fileForUpload.value = parsedFile
    }
    if (typeof e.target?.result !== 'string') throw new Error('Could not get image data from file reader')
    img.src = e.target.result
  }
  fileReader.readAsDataURL(file)
}

watch(fileForUpload, (newFile: File | null) => {
  if (newFile !== null) {
    completeUpload(newFile)
  }
})
</script>

<style lang="scss"></style>
