<template>
  <div class="shadow rounded-xl bg-gray-100 lg:max-w-screen-lg lg:mx-auto">
    <table class="w-full table-fixed">
      <thead>
        <tr class="text-left">
          <th
            v-for="header in headers"
            :key="header.key"
            class="uppercase text-sm font-medium tracking-widest text-gray-600 px-4 py-4"
            :class="[header.boolean ? 'w-16' : '']"
          >
            {{ header.name }}
          </th>
        </tr>
      </thead>
      <tbody class="max-w-full">
        <tr
          v-for="(row, idx) in data"
          :key="row.id"
          class="max-w-full duration-200 transition-all odd:bg-gray-200 text-gray-800 w-full"
          :class="noLinks ? 'cursor-auto' : 'cursor-pointer hover:bg-blue-200/50'"
          @click="noLinks ? null : emit('row-click', idx)"
        >
          <td v-for="header in headers" :key="header.key" class="pl-4 py-4 max-w-lg overflow-visible">
            <p class="truncate" type="text" :name="header.key">
              <PhosphorIcon
                v-if="header.key === 'icon'"
                :iconName="(row as unknown as IconT).icon ?? ''"
                class="text-gray-700"
              />
              {{ getCellValue(header, row) }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts" generic="T extends Item">
import { Header, Item } from '../types'
import PhosphorIcon from './PhosphorIcon.vue'

type IconT = { icon?: string }

const emit = defineEmits<{
  'row-click': [index: number]
}>()

type Props = {
  data: T[]
  headers: Header<T>[]
  noLinks?: boolean
}
withDefaults(defineProps<Props>(), {
  noLinks: false,
})

function getTimestampString(timestamp: string) {
  const date = new Date(timestamp)
  return getDayString(date.getDay()) + ' ' + date.toLocaleTimeString().substring(0, 5)
}
function getDayString(dayNum: number) {
  return ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'][dayNum]
}
function getCellValue(header: Header<T>, row: T) {
  if (header.date) return getTimestampString(row[header.key] as string)
  if (header.boolean) return row[header.key] ? '✅' : '❌'
  return row[header.key]
}
</script>

<style lang="scss"></style>
