<template>
  <input
    type="text"
    v-model="value"
    class="border-2 rounded focus:outline-none border-gray-300 p-4 flex-grow read-only:focus:border-gray-600 read-only:bg-gray-200 focus:border-blue-600 bg-white"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

const emit = defineEmits<{ 'update:modelValue': [value: string] }>()

type Props = {
  modelValue?: string
}
const props = withDefaults(defineProps<Props>(), { modelValue: '' })

const value = computed({
  get: () => props.modelValue,
  set: (newVal: string) => emit('update:modelValue', newVal),
})
</script>

<style></style>
