<template>
  <div class="fixed bottom-12 right-12 space-y-2" id="toaster-container">
    <transition-group name="slide-fade">
      <Toast
        v-for="toast in toasts"
        :key="toast.id"
        :type="toast.type"
        :header="toast.header"
        :text="toast.text"
        @close="closeToast(toast.id)"
      />
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { useToaster } from '../hooks/useToaster'
import Toast from './Toast.vue'

const { closeToast, toasts } = useToaster()
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
</style>
