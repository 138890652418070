<template>
  <div class="flex flex-col items-center px-2 py-4 sm:px-8 sm:py-12 md:px-12 md:py-16">
    <Table :headers="headers" :data="seminars" @row-click="openRow" />
    <div class="max-w-screen-lg w-full flex justify-end mt-6">
      <VariantButton variant="blue" @click="createNew">Legg til ny</VariantButton>
    </div>
    <Modal v-if="selectedRow" @close="selectedRow = null" :showClose="true" :backgroundClose="true">
      <div class="flex flex-col space-y-2 w-full">
        <h1 class="text-2xl font-semibold">
          {{ selectedRow && selectedRow.id ? 'Rediger seminar' : 'Nytt seminar' }}
        </h1>
        <TextField v-model="selectedRow.speaker" placeholder="Taler" />
        <TextField v-model="selectedRow.title" placeholder="Tittel" />
        <TextField v-model="selectedRow.image" placeholder="BildeURL" />
        <TextArea v-model="selectedRow.description" placeholder="Beskrivelse" />
        <div class="flex space-x-2 justify-end">
          <VariantButton variant="blue" @click="save">Lagre</VariantButton>
          <VariantButton v-if="deletable" variant="red" @click="deleteArticle">Slett</VariantButton>
          <VariantButton variant="gray" @click="selectedRow = null">Avbryt</VariantButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import Modal from '../components/Modal.vue'
import Table from '../components/Table.vue'
import TextArea from '../components/TextArea.vue'
import TextField from '../components/TextField.vue'
import VariantButton from '../components/VariantButton.vue'
import { useToaster } from '../hooks/useToaster'
import { useSeminarStore } from '../stores/main/seminars'
import { deepcopy } from '../stores/utils'
import { Header, Seminar } from '../types'

const selectedRow = ref<Seminar | null>(null)
const headers: Header<Seminar>[] = [
  {
    name: 'Taler',
    key: 'speaker',
  },
  {
    name: 'Tittel',
    key: 'title',
  },
  {
    name: 'Bilde',
    key: 'image',
  },
]

const toaster = useToaster()
const seminarStore = useSeminarStore()

const seminars = computed(() => seminarStore.list.toSorted((a, b) => (a.title > b.title ? 1 : -1)))
const deletable = computed(() => !!selectedRow.value?.id)

async function deleteArticle() {
  if (!selectedRow.value) return
  try {
    await seminarStore.remove(selectedRow.value)
    selectedRow.value = null
    toaster.success('Suksess!', 'Slettet seminar.')
  } catch (error) {
    console.error(error)
    toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
  }
}

function openRow(rowIndex: number) {
  selectedRow.value = deepcopy(seminars.value[rowIndex])
}

function createNew() {
  selectedRow.value = {
    id: '',
    title: '',
    speaker: '',
    description: '',
    image: '',
  }
}

async function save() {
  if (!selectedRow.value) return
  if (selectedRow.value.id) {
    try {
      await seminarStore.update(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'Oppdaterte seminar.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  } else {
    try {
      await seminarStore.add(selectedRow.value)
      selectedRow.value = null
      toaster.success('Suksess!', 'La til nytt seminar.')
    } catch (error) {
      console.error(error)
      toaster.error('Ai ai ai!', 'Noe gikk galt, gitt.')
    }
  }
}
</script>

<style lang="scss"></style>
