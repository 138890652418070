<template>
  <div class="p-2 sm:p-12 flex justify-center">
    <div class="max-w-screen-lg w-full">
      <h1 class="text-3xl mb-2">Administratorsider for Ung Landsmøtes nettsider</h1>
      <p>
        Velkommen til admin-sidene for innhold på ULs nettside og mobilapper!
        <br /><br />
        Nettsidene er laget av en gjeng med NTNU-studenter med stor kjærlighet for UL. <br /><br />
        Dersom du får noen problemer med sida eller skulle hatt annen hjelp:
        <br />
        Ta gjerne kontakt med Sebastian Vittersø på mail
        <a class="underline text-blue-500" href="mailto:sebastian.vitters@gmail.com">sebastian.vitters@gmail.com</a>
        eller på telefon
        <a class="underline text-blue-500" href="tel:+4795287176">952 87 176</a>.
      </p>
    </div>
  </div>
</template>
