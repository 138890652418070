import { acceptHMRUpdate, defineStore } from 'pinia'

import { DATABASE_URL_ASSOCIATE, DATABASE_URL_MAIN } from '../firebase'
import { Item } from '../types'
import { useReactiveList } from './useReactiveList'

export function defineListStore<T extends Item>(dbKey: string, associate = false) {
  const storePrefix = associate ? 'associate-' : ''
  const store = defineStore(storePrefix + dbKey, () => {
    return useReactiveList<T>(dbKey, associate ? DATABASE_URL_ASSOCIATE : DATABASE_URL_MAIN)
  })

  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
  }

  return store
}
