<template>
  <input
    type="datetime-local"
    v-model="value"
    class="border-2 rounded focus:outline-none border-gray-300 focus:border-blue-600 p-4 bg-white flex-grow max-w-56 (we-never-need-more-width-for-datetime-local)"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

type Props = {
  modelValue?: string
}
const props = withDefaults(defineProps<Props>(), { modelValue: '' })

const value = computed({
  get: () => props.modelValue,
  set: (newVal: string) => emit('update:modelValue', newVal),
})
</script>

<style></style>
