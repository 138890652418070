import { defineStore } from 'pinia'

import { useAssociateAboutStore } from './associate/about'
import { useAssociateContactStore } from './associate/contact'
import { useAssociateEventStore } from './associate/events'
import { useAssociateInformationStore } from './associate/information'
import { useAssociateResourceStore } from './associate/resources'
import { useAboutStore } from './main/about'
import { useEventStore } from './main/events'
import { useInformationStore } from './main/information'
import { useResourceStore } from './main/resources'
import { useSeminarStore } from './main/seminars'
import { useSponsorStore } from './main/sponsors'
import { useTicketStore } from './main/tickets'

export const useRootStore = defineStore('root', () => {
  const isProd = import.meta.env.PROD
  const isDev = !isProd

  async function populate() {
    await Promise.all([
      useAboutStore().populate(),
      useEventStore().populate(),
      useInformationStore().populate(),
      useSeminarStore().populate(),
      useResourceStore().populate(),
      useSponsorStore().populate(),
      useTicketStore().populate(),
      useAssociateAboutStore().populate(),
      useAssociateContactStore().populate(),
      useAssociateEventStore().populate(),
      useAssociateInformationStore().populate(),
      useAssociateResourceStore().populate(),
    ])
  }

  return { populate, isProd, isDev }
})
