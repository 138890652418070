<template>
  <div class="relative">
    <input
      type="text"
      v-model="value"
      class="border-2 rounded focus:outline-none border-gray-300 p-4 w-full read-only:focus:border-gray-600 read-only:bg-gray-200 focus:border-blue-600 bg-white"
      :placeholder="props.placeholder"
    />
    <div class="absolute top-3.5 right-4 text-gray-700">
      <PhosphorIcon :iconName="value" :size="32" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import PhosphorIcon from './PhosphorIcon.vue'

const emit = defineEmits<{ 'update:modelValue': [value: string] }>()

type Props = {
  modelValue?: string
  placeholder?: string
}
const props = withDefaults(defineProps<Props>(), { modelValue: '' })

const value = computed({
  get: () => props.modelValue,
  set: (newVal: string) => emit('update:modelValue', newVal),
})
</script>

<style></style>
