import { getAuth } from 'firebase/auth'
import { createRouter, createWebHistory } from 'vue-router'

import About from './views/About.vue'
import AssociatesAbout from './views/associates/About.vue'
import AssociatesContact from './views/associates/Contact.vue'
import AssociatesInfo from './views/associates/Info.vue'
import AssociatesProgram from './views/associates/Program.vue'
import AssociatesResources from './views/associates/Resources.vue'
import Home from './views/Home.vue'
import ImageUploader from './views/ImageUploader.vue'
import Info from './views/Info.vue'
import Login from './views/Login.vue'
import Program from './views/Program.vue'
import PushNotifications from './views/PushNotifications.vue'
import Resources from './views/Resources.vue'
import Seminars from './views/Seminars.vue'
import Sponsors from './views/Sponsors.vue'
import Stream from './views/Stream.vue'
import Tickets from './views/Tickets.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },

  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/pushvarsler',
    name: 'push-notifications',
    component: PushNotifications,
  },
  {
    path: '/bildeopplastning',
    name: 'image-uploader',
    component: ImageUploader,
  },

  {
    path: '/om',
    name: 'about',
    component: About,
  },
  {
    path: '/info',
    name: 'info',
    component: Info,
  },
  {
    path: '/seminarer',
    name: 'seminars',
    component: Seminars,
  },
  {
    path: '/program',
    name: 'program',
    component: Program,
  },
  {
    path: '/billetter',
    name: 'tickets',
    component: Tickets,
  },
  {
    path: '/ressurser',
    name: 'resources',
    component: Resources,
  },
  {
    path: '/partnere',
    name: 'sponsors',
    component: Sponsors,
  },
  {
    path: '/stream',
    name: 'Stream link',
    component: Stream,
  },

  {
    path: '/medarbeider/om',
    name: 'associate-about',
    component: AssociatesAbout,
  },
  {
    path: '/medarbeider/info',
    name: 'associate-info',
    component: AssociatesInfo,
  },
  {
    path: '/medarbeider/program',
    name: 'associate-program',
    component: AssociatesProgram,
  },
  {
    path: '/medarbeider/ressurser',
    name: 'associate-resources',
    component: AssociatesResources,
  },
  {
    path: '/medarbeider/kontakt',
    name: 'associate-contact',
    component: AssociatesContact,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.path !== '/login'
  const currentUser = getAuth().currentUser

  if (typeof to.query.to === 'string') {
    localStorage.setItem('to', to.query.to)
  }

  if (requiresAuth && !currentUser) {
    return next(`/login?to=${to.path}`)
  }

  if (!requiresAuth && currentUser) {
    // if you're logged in, and you're trying to open '/login'
    const toPath = localStorage.getItem('to')
    if (toPath) {
      // if you had a thing planned after login, from earlier
      localStorage.removeItem('to')
      return next(toPath)
    }

    return next('/')
  }

  if (!to.matched.length) {
    // if you try a path that doesn't exist
    next('/')
  }

  next()
})

export default router
