<template>
  <!-- TODO: Fiks collapse-greia. -->
  <nav
    id="nav"
    class="ul-scroll flex flex-col flex-no-wrap h-view flex-none md:border-r-2 border-gray-300 bg-white transition-all duration-200 overflow-x-hidden items-left"
    :class="[sidebarStore.isOpen ? 'w-64 p-2' : 'z-1 md:z-0 w-0 md:w-16 md:p-2']"
  >
    <div v-for="category in routeCategories" :key="category.name">
      <h3
        v-if="category.name && sidebarStore.isOpen"
        class="ml-2 mt-6 mb-1 text-sm font-medium tracking-widest text-gray-600"
      >
        {{ category.name }}
      </h3>
      <hr v-else-if="category.name && !sidebarStore.isOpen" class="mx-1 my-2 border-2" />

      <router-link
        v-for="(route, idx) in category.routes"
        :key="idx"
        :to="route.path"
        :title="sidebarStore.isOpen ? '' : route.name"
        class="rounded border-2 mt-1 border-white hover:border-gray-400 flex items-center h-12"
        :class="[
          active(route.path) ? 'bg-gray-300' : 'bg-white',
          sidebarStore.isOpen ? 'justify-start p-2' : 'justify-center p-3',
        ]"
      >
        <PhosphorIcon
          :iconName="route.icon"
          class="text-lg"
          :class="[active(route.path) ? 'text-gray-900' : 'text-gray-600', sidebarStore.isOpen ? 'ml-2 mr-4' : 'mx-0']"
        />
        <span
          v-if="sidebarStore.isOpen"
          class="font-semibold text-base text-gray-700 whitespace-nowrap overflow-hidden"
          :class="active(route.path) ? 'text-gray-900' : 'text-gray-600'"
        >
          {{ route.name }}
        </span>
      </router-link>
    </div>

    <div class="mt-auto p-4 pb-0" v-if="sidebarStore.isOpen">
      <button
        class="w-full py-3 rounded focus:outline-none bg-gray-300 active:bg-gray-500 border-2 hover:border-gray-600 active:border-gray-700 font-semibold text-gray-800 active:text-gray-900"
        @click="handleLogout"
      >
        Logg ut
      </button>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import PhosphorIcon from '../components/PhosphorIcon.vue'
import { useAuth } from '../hooks/useAuth'
import { useSidebarStore } from '../stores/extra/sidebar'

const sidebarStore = useSidebarStore()

const auth = useAuth()
function handleLogout() {
  auth.logout()
}

const routeCategories = [
  {
    routes: [
      {
        path: '/',
        name: 'Startside',
        icon: 'house',
      },
      {
        path: '/pushvarsler',
        name: 'Push-varsler',
        icon: 'bell',
      },
      {
        path: '/bildeopplastning',
        name: 'Bildeopplastning',
        icon: 'upload-simple',
      },
    ],
  },
  {
    name: 'UL.NO',
    routes: [
      {
        path: '/om',
        name: 'Om',
        icon: 'users-three',
      },
      {
        path: '/info',
        name: 'Info',
        icon: 'info',
      },
      {
        path: '/seminarer',
        name: 'Seminarer',
        icon: 'presentation-chart',
      },
      {
        path: '/program',
        name: 'Program',
        icon: 'calendar',
      },
      {
        path: '/billetter',
        name: 'Billetter',
        icon: 'ticket',
      },
      {
        path: '/ressurser',
        name: 'Ressurser',
        icon: 'file',
      },
      {
        path: '/partnere',
        name: 'Samarbeidspartnere',
        icon: 'currency-dollar',
      },
      {
        path: '/stream',
        name: 'Stream link',
        icon: 'youtube-logo',
      },
    ],
  },
  {
    name: 'MEDARBEIDER.UL.NO',
    routes: [
      {
        path: '/medarbeider/om',
        name: 'Om',
        icon: 'users-three',
      },
      {
        path: '/medarbeider/info',
        name: 'Info',
        icon: 'info',
      },
      {
        path: '/medarbeider/program',
        name: 'Program',
        icon: 'calendar',
      },
      {
        path: '/medarbeider/ressurser',
        name: 'Ressurser',
        icon: 'file',
      },
      {
        path: '/medarbeider/kontakt',
        name: 'Kontakt-info',
        icon: 'phone',
      },
    ],
  },
]

const route = useRoute()
const active = computed(() => {
  return (path: string) => route.path === path
})
</script>

<style lang="scss"></style>
