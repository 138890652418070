import { child, Database, remove as dbRemove, get, getDatabase, push, ref, set } from 'firebase/database'
import { onMounted, reactive, ref as vueRef } from 'vue'

import { omitId, setId } from '../stores/utils'
import { Item, NoId } from '../types'

export function useReactiveList<T extends Item>(dbKey: string, dbUrl: string) {
  const list: T[] = reactive([])

  const populated = vueRef(false)

  onMounted(async () => {
    if (populated.value) return
    await populate()
    populated.value = true
  })

  function getDb(): Database {
    return getDatabase(undefined, dbUrl)
  }

  async function populate() {
    const db = getDb()
    const dbRef = ref(db, dbKey)
    const snapshot = await get(dbRef)

    if (!snapshot.exists()) return

    const response = snapshot.val() as Record<string, NoId<T>>
    const items = Object.entries(response).map(([id, item]) => setId(item, id))
    list.length = 0
    list.push(...items)
  }

  async function update(item: T) {
    const index = list.findIndex((i) => i.id === item.id)
    if (index === -1) throw new Error("Can't update item not in list!")

    const db = getDb()
    const dbRef = ref(db, `${dbKey}/${item.id}`)
    await set(dbRef, omitId(item))

    list.splice(index, 1, item)
  }

  async function add(item: NoId<T>) {
    const db = getDb()
    const dbRef = await push(child(ref(db), dbKey))
    if (!dbRef.key) throw new Error('dbRef has no key')

    await set(dbRef, item)
    list.push(setId(item, dbRef.key))
  }

  async function remove(item: T) {
    const index = list.findIndex((i) => i.id === item.id)
    if (index === -1) throw new Error("Can't remove item not in list!")

    const db = getDb()
    const dbRef = ref(db, `${dbKey}/${item.id}`)
    await dbRemove(dbRef)

    list.splice(index, 1)
  }

  return { list, populate, update, add, remove }
}
