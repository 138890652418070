<template>
  <label
    for="ul-toggle"
    class="flex w-full gap-4 cursor-pointer select-none items-center"
    @click="$emit('update:modelValue', !value)"
  >
    <div class="relative">
      <input type="checkbox" id="ul-pluss-toggle" class="sr-only" :value="value" />
      <div
        class="box block h-8 w-14 rounded-full transition-all duration-200"
        :style="`background: ${value ? '#39C' : '#CCC'}`"
      />
      <div
        class="dot absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition-all duration-200 transform"
        :class="[value ? 'translate-x-full' : '']"
      />
    </div>
    <span>
      {{ label }}
    </span>
  </label>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

type Props = {
  modelValue?: boolean
  label?: string
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  label: '',
})

const value = computed({
  get: () => props.modelValue,
  set: (newVal: boolean) => emit('update:modelValue', newVal),
})
</script>

<style></style>
