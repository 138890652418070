<template>
  <div class="ul-rich-text overflow-hidden" :class="focused ? 'tiny-mce-focus' : 'tiny-mce-blur'">
    <TinyMCE
      api-key="gbl0qtqvyf5wpliaae95m26pg4uvea8en4dkmguu9hob7b4o"
      :init="options"
      v-model="value"
      @onFocus="focus"
      @onBlur="blur"
    />
  </div>
</template>

<script lang="ts" setup>
import TinyMCE from '@tinymce/tinymce-vue'
import { IPropTypes } from '@tinymce/tinymce-vue/lib/cjs/main/ts/components/EditorPropTypes'
import { computed, ref } from 'vue'

import { uploadFile } from '../stores/utils/fileupload'

const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

type Props = {
  modelValue?: string
  fullHeight?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  fullHeight: false,
})

const focused = ref(false)
const value = computed({
  get() {
    return props.modelValue
  },
  set(newVal) {
    emit('update:modelValue', newVal)
  },
})

const options = computed<IPropTypes['init']>(() => ({
  height: props.fullHeight ? 600 : 300,
  menubar: false,
  statusbar: false,
  plugins: ['advlist', 'lists', 'autolink', 'link', 'table', 'image', 'code'],
  toolbar:
    'undo redo | blocks | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | image table | removeformat | code',
  contextmenu: 'table | copy cut paste',
  branding: false,
  resize: false,
  body_class: 'bg-red-200',
  apply_source_formatting: true,
  automatic_uploads: true,
  images_upload_handler: imageUploadHandler,
}))

function focus() {
  focused.value = true
}
function blur() {
  focused.value = false
}

// src https://github.com/tinymce/tinymce-dist/blob/7.3.0/tinymce.d.ts#L318-L326
type BlobInfo = {
  id: () => string
  name: () => string
  filename: () => string
  blob: () => Blob
  base64: () => string
  blobUri: () => string
  uri: () => string | undefined
}

async function imageUploadHandler(blobInfo: BlobInfo, progress: (percentage: number) => void): Promise<string> {
  const arrayBuffer = await blobInfo.blob().arrayBuffer()
  const parsedFile = new File([arrayBuffer], blobInfo.filename(), { type: blobInfo.blob().type })
  const filename = await uploadFile(parsedFile, false)
  const fileUrl = `https://firebasestorage.googleapis.com/v0/b/ulno-1557913587891.appspot.com/o/files%2F${filename}?alt=media`
  console.log(fileUrl)
  progress(100)
  return fileUrl
}
</script>

<style lang="scss">
.tiny-mce-focus {
  @apply border-blue-600;
  @apply outline-none;
}

.tiny-mce-blur {
  @apply border-gray-300;
  @apply outline-none;
}

.ul-rich-text .tox-tinymce {
  border: none !important;
}
</style>
