<template>
  <header class="w-screen h-16 flex shadow z-1 justify-start items-center" @click="$emit('click')">
    <div class="ml-2 flex justify-center items-center">
      <button
        class="py-2 px-3 rounded-lg focus:outline-none border-2 border-white hover:border-gray-600 active:bg-gray-300"
        @click="sidebar.toggle"
      >
        <PhosphorIcon iconName="list" class="text-xl relative" style="top: 1px" />
      </button>
    </div>
    <img src="/assets/logo.svg" alt="UL logo" class="h-16 p-3 cursor-pointer" @click="$router.push('/')" />
    <div
      v-if="rootStore.isDev"
      class="flex items-center justify-center bg-gray-200 h-6 w-10 border border-gray-400 rounded-sm"
    >
      DEV
    </div>
  </header>
</template>

<script setup lang="ts">
import { useSidebarStore } from '../stores/extra/sidebar'
import { useRootStore } from '../stores/root'
import PhosphorIcon from './PhosphorIcon.vue'

const sidebar = useSidebarStore()
const rootStore = useRootStore()
</script>

<style lang="scss"></style>
