import { child, get, getDatabase, ref, set } from 'firebase/database'
import { defineStore } from 'pinia'
import { onMounted, ref as vueRef } from 'vue'

import { DATABASE_URL_MAIN } from '../../firebase'

export const useStreamStore = defineStore('stream', () => {
  const populated = vueRef(false)

  const streamId = vueRef('')
  const nextStream = vueRef('')

  onMounted(async () => {
    if (populated.value) return
    await populate()
    populated.value = true
  })

  async function populate() {
    const db = getDatabase(undefined, DATABASE_URL_MAIN)
    const dbRef = ref(db, 'stream')
    const snapshot = await get(dbRef)

    const value: null | { id?: string; nextStream?: string } = snapshot.val()
    if (!value) return

    streamId.value = value.id ?? ''
    nextStream.value = value.nextStream ?? ''
  }

  async function updateId(value: string) {
    const db = getDatabase(undefined, DATABASE_URL_MAIN)
    const dbRef = child(ref(db, 'stream'), 'id')
    await set(dbRef, value)
    streamId.value = value
  }

  async function updateNextStream(value: string) {
    const db = getDatabase(undefined, DATABASE_URL_MAIN)
    const dbRef = child(ref(db, 'stream'), 'nextStream')
    await set(dbRef, value)
    nextStream.value = value
  }

  return { streamId, nextStream, updateId, updateNextStream, populate }
})
