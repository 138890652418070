<template>
  <select
    v-model="value"
    class="border-2 rounded focus:outline-none border-gray-300 focus:border-blue-600 p-4 bg-white flex-grow"
  >
    <option v-for="option in options" :key="option">{{ option }}</option>
  </select>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'

const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

type Props = {
  modelValue?: string
  options: string[]
}
const props = defineProps<Props>()

const value = computed({
  get: () => props.modelValue,
  set: (newVal: string | undefined) => newVal && emit('update:modelValue', newVal),
})

onMounted(() => {
  if (!props.modelValue && props.options.length > 0) {
    emit('update:modelValue', props.options[0])
  }
})
</script>

<style></style>
