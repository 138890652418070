import { acceptHMRUpdate, defineStore } from 'pinia'

import { DATABASE_URL_MAIN } from '../../firebase'
import { useReactiveList } from '../../hooks/useReactiveList'
import { Resource } from '../../types'
import { uploadFile } from '../utils/fileupload'

export const useResourceStore = defineStore('resources', () => {
  const { add: addToDb, list, populate, remove, update } = useReactiveList<Resource>('resources', DATABASE_URL_MAIN)

  async function add(file: File) {
    const filename = await uploadFile(file, true)
    await addToDb({
      filename,
      title: file.name,
      subtitle: '',
    })
  }

  return { add, list, populate, remove, update }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useResourceStore, import.meta.hot))
}
