import { acceptHMRUpdate, defineStore } from 'pinia'

import { DATABASE_URL_MAIN } from '../../firebase'
import { useReactiveList } from '../../hooks/useReactiveList'
import { ResourceFile } from '../../types'
import { uploadFile } from '../utils/fileupload'

// Duplikat av stores/main/resources, denne brukes for bilder
export const useFileStore = defineStore('files', () => {
  const { add: addToDb, list, populate, remove, update } = useReactiveList<ResourceFile>('files', DATABASE_URL_MAIN)

  async function add(file: File) {
    const filename = await uploadFile(file, false)
    await addToDb({
      filename,
      title: file.name,
    })
  }

  return { add, list, populate, remove, update }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFileStore, import.meta.hot))
}
