import { toRaw } from 'vue'

import { Item, NoId } from '../../types'

export function setId<T extends Item>(item: NoId<T>, id: string): T {
  return { ...item, id } as T
}

export function omitId<T extends Item>(item: T): NoId<T> {
  const { id, ...rest } = item
  return rest
}

export function deepcopy<T>(value: T): T {
  return window.structuredClone(toRaw(value))
}
